.appWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #131a23;

  .screenWrapper {
    flex: 1 1;
    overflow: hidden;
    width: 100%;
    display: flex;
    flex-direction: row;
    position: relative;
    z-index: 0;
    background-color: #131a23;

    .authNotReady {
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
      background-color: #131a23;
      align-self: stretch;
    }
  }
}
