.login {
  display: flex;
  flex: 1 1 0;

  .loginCard {
    background: white;
    flex: 0.25 0 auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;

    h2,
    h1 {
      font-weight: 400;
    }
    h1 {
      letter-spacing: 0.5px;
    }
    h2 {
      letter-spacing: 2px;
    }

    .loginCardBody {
      margin: 15px 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      .logo {
        height: 100px;
      }
    }
  }
}
