.loginBannerContainer {
  display: flex;
  flex: 0.75 1 0;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  background: #1fdabc;
  background-image: url('../../../../Assets/Media/loginBannerBackground.svg');
  background-repeat: no-repeat;
  background-size: 100%;
  padding: 50px;

  .banner {
    display: flex;
    align-items: stretch;
    background: none;
    margin-bottom: 30px;
    flex: 0.3 1 400px;
    overflow: hidden;

    .loginIllustration {
      flex: 1 1 0;
    }
  }

  .loginPageInfo {
    display: flex;
    flex: 0 0 auto;
    align-items: stretch;
    flex-wrap: wrap;
    align-self: center;

    .productInfo {
      flex-direction: column;
      flex: 0 0 110px;
      text-align: center;
      padding: 15px;
      background: #ffffff;
      box-shadow: 0px 10px 40px rgba(0, 68, 57, 0.25);
      border-radius: 10px;
      margin: 15px auto;

      .title {
        font-weight: 400;
        font-size: 15px;
        text-align: center;
        margin-top: 15px;
        margin-bottom: 0px;
      }
    }
  }
}
