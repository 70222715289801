@font-face {
  font-family: 'Avenir Next';
  src: url('https://d1ahfyukgvb9qs.cloudfront.net/s/assets/fonts/46cf1067-688d-4aab-b0f7-bd942af6efd8.ttf')
    format('truetype');
  font-weight: 400;
}

.loginContentContainer {
  margin: auto 0px;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  .loginContentBody {
    margin: 15px 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    font-family: 'Avenir Next';

    .logo {
      height: 100px;
    }
  }

  .loginCardFooter {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    button {
      display: flex;
      flex-direction: row;
      align-items: stretch;
      height: 46px;
      padding: 0;
      border: 1px solid #000;
      border-radius: 8px;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 15px;
      width: 240px;
      letter-spacing: 0.1px;
      transition: opacity 0.2s;
      overflow: hidden;
      span {
        color: white;
      }
      span:first-child {
        width: 50px;
      }
    }
    button:hover {
      opacity: 0.9;
    }

    .btn {
      .text {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .googleBtn {
      background: #4885ed;
      border-color: #4885ed;
      .googleLogoContainer {
        background-color: #efefef;
      }
      img {
        height: 20px;
      }
    }

    .appleBtn {
      background: #1d1d1f;
      border-color: #1d1d1f;
      color: #ffffff;
      .appleText {
        color: #ffffff;
        padding-right: 20px;
        /* space added to right as length of string is small */
      }
      img {
        height: 25px;
      }
    }

    .logoContainer {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      flex: 0 0 20%;
      height: inherit;
    }
  }
}
