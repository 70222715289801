.cardLoaderContainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;

  .subContainer {
    background-color: white;
    border-radius: 10px;
    padding: 20px;

    .message {
      padding: 20px 20px 10px;
    }
  }

  .transparentBackground {
    background-color: transparent;
  }
}
